"use strict";

$(($) => {
    let lsUser = ls.user;

    /* login */

    $('#yr-popup-login-form').bind('submit', event => {
        lsUser.login('yr-popup-login-form', () => {
            UIkit.modal('#yr-modal-login').hide();
        });
        event.preventDefault();
    });

    /* registration */

    let registrationForm = $('#popup-registration-form');

    registrationForm.find('input.js-ajax-validate').blur(e => {
        let aParams = {};
        if ($(e.target).attr('name') == 'password_confirm') {
            aParams['password'] = $('#popup-registration-user-password').val();
        }
        if ($(e.target).attr('name') == 'password') {
            aParams['password'] = $('#popup-registration-user-password').val();
            let confirm = $('#popup-registration-user-password-confirm').val();
            if (confirm) {
                lsUser.validateRegistrationField('password_confirm', confirm, $('#popup-registration-form'), { password: $(e.target).val() });
            }
        }
        lsUser.validateRegistrationField($(e.target).attr('name'), $(e.target).val(), registrationForm, aParams);
    });
    registrationForm.bind('submit', event => {
        lsUser.registration('popup-registration-form', () => {
            UIkit.modal('#yr-modal-registration').hide();
        });
        event.preventDefault();
    });

    /* password restore */

    $('#yr-popup-reminder-form').bind('submit', event => {
        lsUser.reminder('yr-popup-reminder-form', () => {
            UIkit.modal('#yr-modal-restore-password').hide();
        });
        event.preventDefault();
    });

    /* registration page */

    let registrationPageForm = $('#registration-form');

    registrationPageForm.find('input.js-ajax-validate').blur(event => {
        let aParams = {};
        if ($(event.target).attr('name') == 'password_confirm') {
            aParams['password'] = $('#registration-user-password').val();
        }
        if ($(event.target).attr('name') == 'password') {
            aParams['password'] = $('#registration-user-password').val();
            let confirm = $('#registration-user-password-confirm').val();
            if (confirm) {
                lsUser.validateRegistrationField('password_confirm', confirm, registrationPageForm, { password: $(event.target).val() });
            }
        }
        lsUser.validateRegistrationField($(event.target).attr('name'), $(event.target).val(), registrationPageForm, aParams);
    });
    registrationPageForm.bind('submit', event => {
        lsUser.registration('registration-form');
        event.preventDefault();
    });
    $('#registration-form-submit').attr('disabled', false);

    /* restore page */

    $('#reminder-form').bind('submit', event => {
        lsUser.reminder('reminder-form');
        event.preventDefault();
    });
    $('#reminder-form-submit').attr('disabled', false);

    /* login page */

    $('#login-form').bind('submit', event =>{
        lsUser.login('login-form');
        event.preventDefault();
    });
    $('#login-form-submit').attr('disabled', false);
});
